import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useStyles } from './footerCSS'
import theme from '../../theme'
import { Box, Stack, Link as MuiLink, Typography, Tooltip, IconButton } from '@mui/material'
import { Mail, Phone, LocationOn, AccessTime, ArrowRight, Facebook, YouTube } from '@mui/icons-material'
import { Line } from '../CustomIcon'
import { isEmptyFunc } from '../../utils/LengthUtils'

export default function Footer() {
  const classes = useStyles(theme)
  const { contactInfo } = useSelector((state) => state.common)
  const organizerList = [{ title: '比恩語文', label: '讓語言快樂推動未來', imgSrc: '/assets/images/icon-192x192.png' }]
  const contactList = [
    { label: contactInfo?.address, path: contactInfo?.googleMap, icon: <LocationOn /> },
    { label: contactInfo?.openingHours, path: null, icon: <AccessTime /> },
    { label: contactInfo?.email, path: `mailto:${contactInfo?.email}`, icon: <Mail /> },
    { label: contactInfo?.telephone, path: `tel:${contactInfo?.telephone}`, icon: <Phone /> }
  ]
  const aboutList = [
    { label: '網站地圖', path: '/sitemap' },
    { label: '服務條款', path: '/terms' },
    { label: '常見問題', path: '/faq' }
  ]
  const hintList = [
    { index: 1, label: '建議使用推薦之瀏覽器，以獲得最佳瀏覽體驗：Chrome、Edge、Firefox、Safari' },
    { index: 2, label: `版權所有 © ${new Date().getFullYear()} 比恩語文` }
  ]
  const mediaList = [
    { label: '比恩語文 Line', path: contactInfo?.line, icon: <Line /> },
    { label: '比恩語文 Facebook', path: contactInfo?.facebook, icon: <Facebook /> },
    { label: '比恩語文 Youtube', path: contactInfo?.youtube, icon: <YouTube /> }
  ]

  return (
    <Box className={classes.root} component={'footer'}>
      {/* topSide */}
      <Box className={classes.mainContainer}>
        <div className={'gridArea'}>
          {/* 組織單位 */}
          <Stack direction={'column'} spacing={2} justifyContent={'center'}>
            {organizerList.map((item, index) => (
              <Stack key={index} direction={'row'} alignItems={'center'} spacing={2}>
                <img src={item.imgSrc} width={'100'} />
                <div>
                  <Typography
                    component={'h3'}
                    variant={'h3'}
                    sx={{ fontWeight: 'bold', letterSpacing: '2px', marginBottom: '0.5em' }}
                  >
                    {item.title}
                  </Typography>
                  <Typography component={'h4'} variant={'h6'} sx={{ fontWeight: 'bold', letterSpacing: '4px' }}>
                    {item.label}
                  </Typography>
                </div>
              </Stack>
            ))}
          </Stack>

          {/* 聯絡方式 */}
          <Stack direction={'column'} spacing={2}>
            <Typography
              component={'h3'}
              variant={'h4'}
              sx={{ fontWeight: 'bold', letterSpacing: '2px', color: '#969696' }}
            >
              {'聯絡方式'}
            </Typography>

            <Stack direction={'column'} spacing={1}>
              {contactList.map((item, index) =>
                !isEmptyFunc(item.path) ? (
                  <MuiLink
                    key={index}
                    href={item.path}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    color={'inherit'}
                    underline={'hover'}
                    sx={{ display: 'inline-flex', whiteSpace: 'pre-line' }}
                    title={item.label}
                  >
                    {item.icon}
                    {item.label}
                  </MuiLink>
                ) : (
                  <MuiLink
                    key={index}
                    href={null}
                    color={'inherit'}
                    underline={'none'}
                    sx={{ display: 'inline-flex', whiteSpace: 'pre-line' }}
                    title={item.label}
                  >
                    {item.icon}
                    {item.label}
                  </MuiLink>
                )
              )}
            </Stack>
          </Stack>

          {/* 關於本站 */}
          <Stack direction={'column'} spacing={2}>
            <Typography
              component={'h3'}
              variant={'h4'}
              sx={{ fontWeight: 'bold', letterSpacing: '2px', color: '#969696' }}
            >
              {'關於本站'}
            </Typography>

            <Stack direction={'column'} spacing={1}>
              {aboutList.map((item, index) => (
                <MuiLink
                  key={index}
                  component={Link}
                  to={item.path}
                  color={'inherit'}
                  underline={'hover'}
                  sx={{ display: 'inline-flex', whiteSpace: 'pre-line' }}
                  title={item.label}
                >
                  <ArrowRight />
                  {item.label}
                </MuiLink>
              ))}
            </Stack>
          </Stack>
        </div>
      </Box>

      {/* bottomSide */}
      <Box className={classes.secContainer}>
        <div className={'gridArea'}>
          {/* 注意事項 */}
          <Stack className={'hint'} direction={'column'}>
            {hintList.map((item, index) => (
              <Typography
                key={index}
                component={'small'}
                variant={'string'}
                dangerouslySetInnerHTML={{ __html: item.label }}
              />
            ))}
          </Stack>

          {/* 社群連結 */}
          <Stack direction={'row'} justifyContent={'center'} alignItems={'flex-end'}>
            {mediaList.map((item, index) => (
              <Tooltip key={index} title={item.label} arrow={true}>
                <IconButton href={item.path} target={'_blank'} rel={'noopener noreferrer'} color={'inherit'}>
                  {item.icon}
                </IconButton>
              </Tooltip>
            ))}
          </Stack>
        </div>
      </Box>
    </Box>
  )
}
