import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    zIndex: 1,
    color: '#FFFFFF',
    letterSpacing: '1px'
  },
  mainContainer: {
    padding: '2em 0',
    backgroundColor: '#2b3137',
    '& > .gridArea': {
      maxWidth: '1400px',
      padding: '0 1em',
      margin: '0 auto',
      display: 'grid',
      gridTemplateColumns: '2fr 1.8fr 1fr',
      gridAutoColumns: '1fr',
      gap: '2em',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr'
      },
      '& > div:nth-child(1) .MuiSvgIcon-root': {
        width: '80px',
        height: '80px',
        [theme.breakpoints.down('md')]: {
          width: '60px',
          height: '60px'
        }
      },
      '& > div:nth-child(2) .MuiSvgIcon-root, & > div:nth-child(3) .MuiSvgIcon-root': {
        marginRight: '4px'
      }
    }
  },
  secContainer: {
    padding: '1em 0',
    backgroundColor: '#525252',
    '& > .gridArea': {
      maxWidth: '1400px',
      padding: '0 1em',
      margin: '0 auto',
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gridAutoColumns: '1fr',
      gap: '1em',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr'
      },
      '& > .hint a': {
        color: 'inherit'
      }
    }
  }
}))

export { useStyles }
