import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  username: '',
  userKind: 'public'
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUsername(state, action) {
      state.username = action.payload
    },
    setUserKind(state, action) {
      state.userKind = action.payload
    }
  }
})

export const { setUsername, setUserKind } = authSlice.actions
export default authSlice.reducer
