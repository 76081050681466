import { configureStore, combineReducers } from '@reduxjs/toolkit'
import authReducer from './slice/authSlice'
import commonReducer from './slice/commonSlice'

export default configureStore({
  reducer: combineReducers({
    auth: authReducer,
    common: commonReducer
  })
})
