import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './pages/App'
import WebFont from 'webfontloader'
import './index.css'
import './i18n'

WebFont.load({
  active: () => (sessionStorage.fontsLoaded = true),
  google: { families: ['Noto Sans TC:300,400,500,700&display=swap'] }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)
