import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate, Link, createSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useStyles } from './headerCSS'
import theme from '../../theme'
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  OutlinedInput,
  InputAdornment,
  Hidden,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@mui/material'
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  Share,
  ArrowDropDown,
  Search,
  ExpandLess,
  ExpandMore
} from '@mui/icons-material'
import Headroom from 'react-headroom'
import { isEmptyFunc } from '../../utils/LengthUtils'

export default function Header() {
  const classes = useStyles(theme)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isDialogShow, setIsDialogShow] = useState(false)
  const menuList = [
    {
      name: '開課總覽',
      subMenu: [
        { name: '德文', path: '/classes?language=de' },
        { name: '法文', path: '/classes?language=fr' },
        { name: '西文', path: '/classes?language=es' },
        { name: '英文', path: '/classes?language=en' },
        { name: '其他', path: '/classes?language=others' },
        { name: '課程洽詢', path: '/reservations' }
      ]
    },
    {
      name: '教學課程',
      subMenu: [
        { name: '師資簡介', path: '/teachers' },
        { name: '課程型態', path: '/class-intro' }
      ]
    },
    {
      name: '教綱與測驗',
      subMenu: [
        { name: '課程綱要', path: '/class-schema' },
        { name: '教材簡介', path: '/materials' },
        { name: '教學資源', path: '/resources' },
        { name: '比恩行事曆', path: '/calendar' },
        { name: '分級測驗', path: '/reservations?exam_only=true' }
      ]
    },
    {
      name: '線上課程',
      subMenu: [
        { name: '課程清單', path: '/classes?teaching_mode=online' },
        { name: '熱門推薦', path: '/recommend' }
      ]
    },
    {
      name: '關於我們',
      subMenu: [
        { name: '比恩簡介', path: '/about-us' },
        { name: '交通位置', path: '/contact' },
        { name: '客服專區', path: '/service' },
        { name: '常見問題', path: '/faq' }
        // { name: '繳費方式', path: '/pay' }
      ]
    }
  ]

  return (
    <Headroom style={{ zIndex: 100 }}>
      <Box className={classes.root} component={'header'} sx={{ boxShadow: 4 }}>
        <Stack className={classes.main} direction={'row'} justifyContent={'space-between'} alignItems={'stretch'}>
          {/* left side */}
          <Stack
            className={'leftSide'}
            direction={'row'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            spacing={1}
          >
            {/* website logo */}
            <ButtonBase
              className={'logoAnchor'}
              focusRipple={true}
              onClick={() => navigate('/')}
              component={Link}
              to={'/'}
              title={'首頁'}
            >
              <img src={'/assets/images/logo.png'} />
              <label>{'Bien Languages Academy'}</label>
            </ButtonBase>
          </Stack>

          {/* right side */}
          <Stack
            className={'rightSide'}
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            spacing={1}
          >
            {/* desktop */}
            <Stack
              className={'desktopMode'}
              direction={'column'}
              justifyContent={'space-evenly'}
              alignItems={'stretch'}
            >
              <Stack className={'navBar'} direction={'row'} alignItems={'center'} spacing={1}>
                {menuList.map((item, index) => (
                  <Box key={index} className={'navigation'}>
                    <ButtonBase className={'funcAnchor'} focusRipple={true} title={item.name}>
                      {item.name}
                      <ArrowDropDown />
                    </ButtonBase>
                    <Box className={'navigationContent'}>
                      <List component={'nav'}>
                        {item.subMenu.map((itm, idx) => (
                          <ListItemButton
                            key={idx}
                            onClick={() => navigate(itm.path)}
                            component={Link}
                            to={itm.path}
                            title={itm.name}
                          >
                            <ListItemText>{itm.name}</ListItemText>
                          </ListItemButton>
                        ))}
                      </List>
                    </Box>
                  </Box>
                ))}
              </Stack>

              <Stack className={'searchInput'} direction={'row'} alignItems={'center'}>
                <OutlinedInput
                  fullWidth={true}
                  variant={'outlined'}
                  size={'small'}
                  placeholder={'課程查詢'}
                  inputProps={{ readOnly: true }}
                  endAdornment={
                    <InputAdornment position={'end'}>
                      <Button variant={'contained'}>
                        <Search />
                      </Button>
                    </InputAdornment>
                  }
                  onClick={() => setIsDialogShow(true)}
                  title={'課程查詢'}
                />
                <ClassesSelecterDialog isOpen={isDialogShow} onClose={() => setIsDialogShow(false)} />
              </Stack>
            </Stack>

            {/* mobile */}
            <Box className={'mobileMode'}>
              <IconButton focusRipple={true} onClick={() => setIsDrawerOpen(true)} title={'開啟選單'}>
                <MenuIcon />
              </IconButton>

              <Hidden mdUp={true}>
                <Drawer
                  component={'aside'}
                  anchor={'right'}
                  PaperProps={{ className: classes.drawer }}
                  open={isDrawerOpen}
                  onClose={() => setIsDrawerOpen(false)}
                >
                  <Stack
                    className={'topSide'}
                    direction={'row'}
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                    spacing={1}
                  >
                    <IconButton onClick={() => setIsDrawerOpen(false)} title={'關閉選單'}>
                      <CloseIcon />
                    </IconButton>
                  </Stack>

                  <Stack
                    className={'bottomSide'}
                    direction={'column'}
                    alignItems={'center'}
                    spacing={1}
                    sx={{ marginTop: '8px', flexGrow: '1' }}
                  >
                    {menuList.map((item, index) => (
                      <MobileMenuItem key={index} item={item} onClick={(value) => setIsDrawerOpen(value)} />
                    ))}
                  </Stack>

                  {navigator?.share && (
                    <Box className={'bottomSide'}>
                      <Divider orientation={'horizontal'} />

                      <Box className={'navigation'}>
                        <ButtonBase
                          className={'funcAnchor'}
                          focusRipple={true}
                          sx={{ gap: '8px' }}
                          onClick={() =>
                            navigator.share({
                              title: t('common.website_title'),
                              text: t('share.welcome', { websiteTitle: t('common.website_title') }),
                              url: window.location.href
                            })
                          }
                          title={t('share.share_page')}
                        >
                          <Share />
                          {t('share.share_page')}
                        </ButtonBase>
                      </Box>
                    </Box>
                  )}
                </Drawer>
              </Hidden>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Headroom>
  )
}

MobileMenuItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func
}

function MobileMenuItem({ item = {}, onClick = () => undefined }) {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box className={'navigation'}>
      <ButtonBase
        className={'funcAnchor'}
        onClick={() => setIsOpen((prev) => !prev)}
        sx={{ width: '100%' }}
        title={item.name}
      >
        {item.name}
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ButtonBase>
      <Collapse in={isOpen}>
        <List component={'nav'}>
          {item.subMenu.map((itm, idx) => (
            <ListItemButton
              key={idx}
              onClick={() => {
                navigate(itm.path)
                onClick(false)
              }}
              component={Link}
              to={itm.path}
              title={itm.name}
            >
              <ListItemText>{itm.name}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </Box>
  )
}

ClassesSelecterDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

function ClassesSelecterDialog({
  // param
  isOpen = false,
  onClose = () => undefined
}) {
  const navigate = useNavigate()
  const {
    language: languageObj,
    teachingMode: teachingModeObj,
    classGoal: classGoalObj
  } = useSelector((state) => state.common)?.commonInfo
  const languageOptions = Object.keys(languageObj).map((item) => ({ value: item, label: languageObj[item] }))
  const teachingOptions = Object.keys(teachingModeObj).map((item) => ({
    value: item,
    label: `${teachingModeObj[item]}課程`
  }))
  const classGoalOptions = Object.keys(classGoalObj).map((item) => ({ value: item, label: classGoalObj[item] }))
  const [language, setLanguage] = useState('')
  const [teachingMode, setTeachingMode] = useState('')
  const [classGoal, setClassGoal] = useState('')

  useEffect(() => {
    if (!isOpen) {
      setLanguage('')
      setTeachingMode('')
      setClassGoal('')
    }
  }, [isOpen])

  const handleCreateNavigateObj = (obj = {}, pathname = location?.pathname) => {
    // define default value
    const baseObj = {
      language: language,
      teaching_mode: teachingMode,
      class_goal: classGoal
    }
    // combine current value and new value
    const combineObj = { ...baseObj, ...obj }
    // filter empty keys
    const withoutUndefined = Object.keys(combineObj)
      .map((item) => ({ key: item, value: combineObj[item] }))
      .filter((item) => !isEmptyFunc(item.value))
      .reduce((prev, curr) => Object.assign(prev, { [curr.key]: curr.value }), {})

    return { pathname: pathname, search: `?${createSearchParams(withoutUndefined)}` }
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'xs'}>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
        <Box>
          <Typography variant={'h5'} gutterBottom={true} sx={{ borderBottom: '1px solid #707070' }}>
            {'課程語言'}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1em' }}>
            {languageOptions.map((item, idx) => (
              <Button
                key={idx}
                sx={{
                  borderRadius: '18px',
                  letterSpacing: '1px',
                  backgroundColor: [item.value].includes(language) ? undefined : '#fff'
                }}
                color={'primary'}
                variant={[item.value].includes(language) ? 'contained' : 'outlined'}
                size={'medium'}
                disableElevation={true}
                onClick={() => setLanguage(item.value)}
              >
                {item.label}
              </Button>
            ))}
          </Box>
        </Box>
        <Box>
          <Typography variant={'h5'} gutterBottom={true} sx={{ borderBottom: '1px solid #707070' }}>
            {'課程型態'}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1em' }}>
            {teachingOptions.map((item, idx) => (
              <Button
                key={idx}
                sx={{
                  borderRadius: '18px',
                  letterSpacing: '1px',
                  backgroundColor: [item.value].includes(teachingMode) ? undefined : '#fff'
                }}
                color={'primary'}
                variant={[item.value].includes(teachingMode) ? 'contained' : 'outlined'}
                size={'medium'}
                disableElevation={true}
                onClick={() => setTeachingMode(item.value)}
              >
                {item.label}
              </Button>
            ))}
          </Box>
        </Box>
        <Box>
          <Typography variant={'h5'} gutterBottom={true} sx={{ borderBottom: '1px solid #707070' }}>
            {'課程班別'}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1em' }}>
            {classGoalOptions.map((item, idx) => (
              <Button
                key={idx}
                sx={{
                  borderRadius: '18px',
                  letterSpacing: '1px',
                  backgroundColor: [item.value].includes(classGoal) ? undefined : '#fff'
                }}
                color={'primary'}
                variant={[item.value].includes(classGoal) ? 'contained' : 'outlined'}
                size={'medium'}
                disableElevation={true}
                onClick={() => setClassGoal(item.value)}
              >
                {item.label}
              </Button>
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant={'text'} disableElevation={true} sx={{ borderRadius: '18px' }} onClick={onClose}>
          {'取消'}
        </Button>
        <Button
          variant={'contained'}
          disableElevation={true}
          sx={{ borderRadius: '18px' }}
          onClick={() => {
            navigate(handleCreateNavigateObj({}, '/classes'))
            onClose()
          }}
        >
          {'查詢'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
