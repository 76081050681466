import Home from './pages/Home'
import News from './pages/News'
import NewsDetail from './pages/NewsDetail'
import Recommend from './pages/Recommend'
import Classes from './pages/Classes'
import ClassesDetail from './pages/ClassesDetail'
import Resources from './pages/Resources'
import ResourcesDetail from './pages/ResourcesDetail'
import Teachers from './pages/Teachers'
import Materials from './pages/Materials'
import ClassIntro from './pages/ClassIntro'
import ClassSchema from './pages/ClassSchema'
import AboutUs from './pages/AboutUs'
import Calendar from './pages/Calendar'
import Contact from './pages/Contact'
import Service from './pages/Service'
import Faq from './pages/Faq'
import Terms from './pages/Terms'
import Reservations from './pages/Reservations'
import Sitemap from './pages/Sitemap'
import AdminRoot from './pages/AdminRoot'
import AdminCarousel from './pages/AdminCarousel'
import AdminMaterialCarousel from './pages/AdminMaterialCarousel'
import AdminNews from './pages/AdminNews'
import AdminRecommend from './pages/AdminRecommend'
import AdminClasses from './pages/AdminClasses'
import AdminResources from './pages/AdminResources'
import AdminTeachers from './pages/AdminTeachers'
import AdminMaterials from './pages/AdminMaterials'
import AdminClassIntro from './pages/AdminClassIntro'
import AdminClassSchema from './pages/AdminClassSchema'
import AdminAboutUs from './pages/AdminAboutUs'
import AdminCalendar from './pages/AdminCalendar'
import AdminContact from './pages/AdminContact'
import AdminSocialMedia from './pages/AdminSocialMedia'
import AdminFaq from './pages/AdminFaq'
import AdminTerms from './pages/AdminTerms'
import AdminExam from './pages/AdminExam'
import AdminReservations from './pages/AdminReservations'
import AdminLineHelper from './pages/AdminLineHelper'

const routes = [
  {
    path: '/',
    name: 'routes.home',
    component: Home,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/news',
    name: 'routes.news',
    component: News,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/news/:uuid',
    name: 'routes.news_detail',
    component: NewsDetail,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/recommend',
    name: 'routes.recommend',
    component: Recommend,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/classes',
    name: 'routes.classes',
    component: Classes,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/classes/:uuid',
    name: 'routes.classes_detail',
    component: ClassesDetail,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/resources',
    name: 'routes.resources',
    component: Resources,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/resources/:uuid',
    name: 'routes.resources_detail',
    component: ResourcesDetail,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/teachers',
    name: 'routes.teachers',
    component: Teachers,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/materials',
    name: 'routes.materials',
    component: Materials,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/class-intro',
    name: 'routes.class_intro',
    component: ClassIntro,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/class-schema',
    name: 'routes.class_schema',
    component: ClassSchema,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/about-us',
    name: 'routes.about_us',
    component: AboutUs,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/calendar',
    name: 'routes.calendar',
    component: Calendar,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/contact',
    name: 'routes.contact',
    component: Contact,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/service',
    name: 'routes.service',
    component: Service,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/faq',
    name: 'routes.faq',
    component: Faq,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/terms',
    name: 'routes.terms',
    component: Terms,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/reservations',
    name: 'routes.reservations',
    component: Reservations,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/sitemap',
    name: 'routes.sitemap',
    component: Sitemap,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/administrator',
    name: 'routes.admin',
    component: AdminRoot,
    role: ['public', 'user', 'admin']
  },
  {
    path: '/administrator/carousel',
    name: 'routes.admin_carousel',
    component: AdminCarousel,
    role: ['admin']
  },
  {
    path: '/administrator/material-carousel',
    name: 'routes.admin_material_carousel',
    component: AdminMaterialCarousel,
    role: ['admin']
  },
  {
    path: '/administrator/news',
    name: 'routes.admin_news',
    component: AdminNews,
    role: ['admin']
  },
  {
    path: '/administrator/recommend',
    name: 'routes.admin_recommend',
    component: AdminRecommend,
    role: ['admin']
  },
  {
    path: '/administrator/classes',
    name: 'routes.admin_classes',
    component: AdminClasses,
    role: ['admin']
  },
  {
    path: '/administrator/resources',
    name: 'routes.admin_resources',
    component: AdminResources,
    role: ['admin']
  },
  {
    path: '/administrator/teachers',
    name: 'routes.admin_teachers',
    component: AdminTeachers,
    role: ['admin']
  },
  {
    path: '/administrator/materials',
    name: 'routes.admin_materials',
    component: AdminMaterials,
    role: ['admin']
  },
  {
    path: '/administrator/class-intro',
    name: 'routes.admin_class_intro',
    component: AdminClassIntro,
    role: ['admin']
  },
  {
    path: '/administrator/class-schema',
    name: 'routes.admin_class_schema',
    component: AdminClassSchema,
    role: ['admin']
  },
  {
    path: '/administrator/about-us',
    name: 'routes.admin_about_us',
    component: AdminAboutUs,
    role: ['admin']
  },
  {
    path: '/administrator/calendar',
    name: 'routes.admin_calendar',
    component: AdminCalendar,
    role: ['admin']
  },
  {
    path: '/administrator/contact',
    name: 'routes.admin_contact',
    component: AdminContact,
    role: ['admin']
  },
  {
    path: '/administrator/social-media',
    name: 'routes.admin_social_media',
    component: AdminSocialMedia,
    role: ['admin']
  },
  {
    path: '/administrator/faq',
    name: 'routes.admin_faq',
    component: AdminFaq,
    role: ['admin']
  },
  {
    path: '/administrator/terms',
    name: 'routes.admin_terms',
    component: AdminTerms,
    role: ['admin']
  },
  {
    path: '/administrator/exam',
    name: 'routes.admin_exam',
    component: AdminExam,
    role: ['admin']
  },
  {
    path: '/administrator/reservations',
    name: 'routes.admin_reservations',
    component: AdminReservations,
    role: ['admin']
  },
  {
    path: '/administrator/line-helper',
    name: 'routes.admin_line_helper',
    component: AdminLineHelper,
    role: ['admin']
  }
]

export default routes
