import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

// init
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)

export const usePageTracking = () => {
  const location = useLocation()

  // update when url change
  useEffect(() => {
    const page = location.pathname + location.search
    ReactGA.send({ hitType: 'pageview', page: page })
  }, [location])
}
