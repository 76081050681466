import React, { Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { usePageTracking } from '../CustomHook'
import Loading from '../Loading'
import { useStyles } from './contentCSS'
import theme from '../../theme'
import routes from '../../routes'

export default function Contents() {
  const classes = useStyles(theme)
  const { userKind } = useSelector((state) => state.auth)
  const allowAccessRoutes = routes.filter((item) => item.role.includes(userKind))
  usePageTracking()

  return (
    <section className={classes.root}>
      <Suspense fallback={<Loading sx={{ position: 'inherit' }} />}>
        <Routes>
          {allowAccessRoutes.map(({ path, component, props }, key) => (
            <Route key={key} path={path} element={React.createElement(component, props)} />
          ))}
          <Route path={'*'} element={<Navigate replace={true} to={'/'} />} />
        </Routes>
      </Suspense>
    </section>
  )
}
