import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column'
  },
  main: {
    flex: 'auto',
    width: '100%',
    margin: '0 auto',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  '@global': {
    /* snackbar color */
    '.SnackbarItem-variantSuccess': {
      backgroundColor: (theme) => `${theme.palette.primary.main} !important`
    },
    '.SnackbarItem-variantWarning': {
      backgroundColor: (theme) => `${theme.palette.warning.main} !important`
    },
    '.SnackbarItem-variantError': {
      backgroundColor: (theme) => `${theme.palette.error.main} !important`
    },
    '.SnackbarItem-variantInfo': {
      backgroundColor: (theme) => `${theme.palette.info.main} !important`
    },
    /* scrollbar for Chrome, Edge, and Safari */
    '*::-webkit-scrollbar': {
      width: '4px',
      height: '4px'
    },
    '*::-webkit-scrollbar-thumb': {
      borderRadius: '2px',
      background: (theme) => theme.palette.primary.main
    },
    '*::-webkit-scrollbar-corner': {
      background: '#ffffff'
    },
    /* scrollbar for Firefox */
    '*': {
      scrollbarWidth: 'none',
      scrollbarColor: (theme) => `${theme.palette.primary.main} #ffffff`
    },
    /* hide the reCAPTCHA badge */
    '.grecaptcha-badge': {
      visibility: 'hidden'
    }
  }
}))

export { useStyles }
