import React from 'react'
import { SvgIcon } from '@mui/material'

// Facebook
export function Facebook(props) {
  return (
    <SvgIcon {...props}>
      <path d="m23.83333,12.02958c0,-6.532 -5.30133,-11.83333 -11.83333,-11.83333s-11.83333,5.30133 -11.83333,11.83333c0,5.72733 4.07067,10.49617 9.46667,11.59667l0,-8.04667l-2.36667,0l0,-3.55l2.36667,0l0,-2.95833c0,-2.28383 1.85783,-4.14167 4.14167,-4.14167l2.95833,0l0,3.55l-2.36667,0c-0.65083,0 -1.18333,0.5325 -1.18333,1.18333l0,2.36667l3.55,0l0,3.55l-3.55,0l0,8.22417c5.97583,-0.59167 10.65,-5.63267 10.65,-11.77417z" />
    </SvgIcon>
  )
}
