import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    width: '100%',
    backgroundColor: '#F2F2F2',
    backgroundImage: (theme) => `radial-gradient(${theme.palette.primary.main}80 0.75px, #F2F2F2 0.75px)`,
    backgroundSize: '15px 15px',
    '& > div': {
      minHeight: '80vh',
      maxWidth: '1400px',
      margin: '0 auto',
      // padding: '1em',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 3px 3px -2px #00000033, 0px 3px 4px 0px #00000024, 0px 1px 8px 0px #0000001f'
    }
  }
}))

export { useStyles }
