import axiosData from './AxiosDataService'

/* forestage */
// common
export const getDataBasicData = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/basic-data',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// home
export const getDataHome = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/home',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// recommend
export const getDataHotNewClasses = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/hot-new-classes',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// classes
export const getDataNewClasses = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/new-classes',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getDataNewClassesDetail = async (data, token) => {
  try {
    const initOptions = {
      url: `/data/new-classes/${data.uuid}`,
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// news
export const getDataNews = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/news',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getDataNewsDetail = async (data, token) => {
  try {
    const initOptions = {
      url: `/data/news/${data.uuid}`,
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// resources
export const getDataFreeResources = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/free-resources',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getDataFreeResourcesDetail = async (data, token) => {
  try {
    const initOptions = {
      url: `/data/free-resources/${data.uuid}`,
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// teachers
export const getDataTeachers = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/teachers',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// materials
export const getDataMaterials = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/materials',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// calendar
export const getDataCalendar = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/calendar',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// class-intro
export const getDataClassIntro = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/class-intro',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// about-us
export const getDataAboutUs = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/about-us',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// contact
export const getDataContact = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/contact',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// faq
export const getDataQaGeneral = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/qa-general',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// exam
export const getDataExam = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/exam',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// reservations
export const postDataReservations = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/reservations',
      method: 'POST',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// schema
export const getDataCourseOutline = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/course-outline',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// unclassified-data
export const getDataUnclassifiedData = async (data, token) => {
  try {
    const initOptions = {
      url: '/data/unclassified-data',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

/* backstage */
// auth
export const postApiLogin = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/login',
      method: 'POST',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const postApiLogout = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/logout',
      method: 'POST',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// common
export const getApiCommonData = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/common-data',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// home (main carousel)
export const getApiCarousel = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/carousel',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const postApiCarousel = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/carousel',
      method: 'POST',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiCarousel = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/carousel/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const deleteApiCarousel = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/carousel/${data.uuid}`,
      method: 'DELETE',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// home (material carousel)
export const getApiMaterialCarousel = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/material-carousel',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiMaterialCarousel = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/material-carousel/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// classes
export const getApiNewClasses = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/new-classes',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const postApiNewClasses = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/new-classes',
      method: 'POST',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiNewClasses = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/new-classes/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const deleteApiNewClasses = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/new-classes/${data.uuid}`,
      method: 'DELETE',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// news
export const getApiNews = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/news',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const postApiNews = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/news',
      method: 'POST',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiNews = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/news/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const deleteApiNews = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/news/${data.uuid}`,
      method: 'DELETE',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// resources
export const getApiFreeResources = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/free-resources',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const postApiFreeResources = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/free-resources',
      method: 'POST',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiFreeResources = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/free-resources/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const deleteApiFreeResources = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/free-resources/${data.uuid}`,
      method: 'DELETE',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// teachers
export const getApiTeachers = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/teachers',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const postApiTeachers = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/teachers',
      method: 'POST',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiTeachers = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/teachers/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const deleteApiTeachers = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/teachers/${data.uuid}`,
      method: 'DELETE',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// materials
export const getApiMaterials = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/materials',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const postApiMaterials = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/materials',
      method: 'POST',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiMaterials = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/materials/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const deleteApiMaterials = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/materials/${data.uuid}`,
      method: 'DELETE',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// calendar
export const getApiCalendar = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/calendar',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiCalendar = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/calendar/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// class-intro
export const getApiClassIntro = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/class-intro',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiClassIntro = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/class-intro/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// about-us
export const getApiAboutUs = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/about-us',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiAboutUs = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/about-us/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// about-us-detail
export const postApiAboutUsDetail = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/about-us-detail',
      method: 'POST',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiAboutUsDetail = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/about-us-detail/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const deleteApiAboutUsDetail = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/about-us-detail/${data.uuid}`,
      method: 'DELETE',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// contact
export const getApiContact = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/contact',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiContact = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/contact/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// contact (social media)
export const getApiSocialMedia = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/social-media',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const postApiSocialMedia = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/social-media',
      method: 'POST',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiSocialMedia = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/social-media/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const deleteApiSocialMedia = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/social-media/${data.uuid}`,
      method: 'DELETE',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// faq
export const getApiQaGeneral = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/qa-general',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const postApiQaGeneral = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/qa-general',
      method: 'POST',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiQaGeneral = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/qa-general/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const deleteApiQaGeneral = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/qa-general/${data.uuid}`,
      method: 'DELETE',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// exam
export const getApiExam = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/exam',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiExam = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/exam/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// exam (reservations)
export const getApiReservations = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/reservations',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiReservations = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/reservations/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const deleteApiReservations = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/reservations/${data.uuid}`,
      method: 'DELETE',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// schema
export const getApiCourseOutline = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/course-outline',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiCourseOutline = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/course-outline/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// unclassified-data
export const getApiUnclassifiedData = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/unclassified-data',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiUnclassifiedData = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/unclassified-data/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// line-users
export const getApiLineUsersData = async (data, token) => {
  try {
    const initOptions = {
      url: '/api/line-users',
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const putApiLineUsersData = async (data, token) => {
  try {
    const initOptions = {
      url: `/api/line-users/${data.uuid}`,
      method: 'PUT',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

// assets
export const getAssetsCountries = async (data, token) => {
  const { protocol, host } = window.location
  try {
    const initOptions = {
      url: `${protocol}//${host}${process.env.PUBLIC_URL}/assets/json/countries.json`,
      method: 'GET',
      data: { ...data, uuid: undefined },
      token: token
    }

    const res = await axiosData(initOptions)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}
