import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // common
  commonInfo: {
    language: {
      de: '德文',
      fr: '法文',
      es: '西文',
      en: '英文',
      others: '其他'
    },
    teachingMode: {
      contact: '實體',
      online: '遠距',
      both: '實體+遠距'
    },
    classGoal: {
      group: '團體',
      tutor: '家教',
      exam: '檢定',
      teenager: '青少年',
      children: '兒童',
      expat: '企業/外派'
    },
    classIntroType: {
      contact: '實體',
      online: '遠距',
      group: '團體',
      tutor: '家教',
      exam: '檢定',
      teenager: '青少年',
      children: '兒童',
      expat: '企業/外派'
    },
    reservationType: {
      a1: 'A1',
      a2: 'A2',
      b1: 'B1',
      b2: 'B2或以上'
    },
    reservationEnglishType: {
      gsat: '學測題型',
      ielts: '雅思題型',
      toefl: '托福題型',
      toeic: '多益題型'
    },
    teachingPeriod: {
      byWeek: {
        weekday: '平日',
        weekend: '週末'
      },
      byDay: {
        morning: '早上',
        afternoon: '下午',
        night: '晚上'
      }
    },
    newsType: {
      notice: '新聞資訊',
      new_classes: '課程資訊',
      others: '其他訊息'
    },
    tuitionType: {
      original: '原價',
      early: '早鳥價',
      twin: '雙人價',
      tutor: '家教, 每人/小時'
    },
    faqType: {
      common: '常見Q&A',
      share: '概念分享'
    },
    innerResourceType: {
      google_drive: 'Google Drive 文件',
      youtube: 'YouTube 影片',
      youtube_playlist: 'YouTube 播放清單'
    },
    resourceLevel: {
      a1: 'A1',
      a2: 'A2',
      b1: 'B1',
      b2: 'B2或以上'
    },
    assetsFlagType: {
      none: '不顯示',
      image: '圖片',
      video_link: '影片'
    }
  },
  // contact
  contactInfo: {
    notice: [
      '語言課程諮詢、訊息詢問或意見反映，於我們上班時段，可透過LINE或來電，將有行政人員為您解答或服務。',
      '若非上班時段或假期，您可以透過官方LINE留給給我們，告知我們您的姓名及需要我們協助的事項，我們將盡速與您回覆。',
      '如果您想預約體驗課程，請您點擊預約體驗課程，亦可透過官方LINE，留下您的訊息。我們在了解您的需求後，為您安排合適的內容。'
    ],
    email: 'bien@bien-languages.com',
    telephone: '(02)2252-2232',
    address: '220 新北市板橋區文化路一段120號6樓',
    openingHours: '週一至週五 13:30 - 21:50\n週六 10:00 - 21:50\n週日 10:00 - 18:00',
    googleMap: 'https://g.page/BienLanguages',
    line: 'https://page.line.me/bien-languages',
    facebook: 'https://www.facebook.com/bienlanguagesfanclub',
    youtube: 'https://www.youtube.com/user/BienLanguages'
  },
  // media
  mediaInfo: [
    {
      index: 5,
      name: '課程洽詢',
      imageUrl: '/images/social-media/6289241dd33ff8d61901414d_image.png',
      imageLink: '/reservations'
    },
    {
      index: 4,
      name: '比恩語文Line',
      imageUrl: '/images/social-media/5f1e9e906cc87637b0a673ec_image.png',
      imageLink: 'https://page.line.me/bien-languages?openQrModal=true'
    },
    {
      index: 3,
      name: '比恩語文Facebook',
      imageUrl: '/images/social-media/5f3a1aeb30d63843aaa447de_image.png',
      imageLink: 'https://www.facebook.com/bienlanguagesfanclub'
    },
    {
      index: 2,
      name: '比恩語文Youtube',
      imageUrl: '/images/social-media/5f1361ff4aca6689aed5a3e4_image.png',
      imageLink: 'https://www.youtube.com/user/BienLanguages'
    },
    {
      index: 1,
      name: '比恩語文Instagram',
      imageUrl: '/images/social-media/6138abe09faf42aee23cb417_image.png',
      imageLink: 'https://www.instagram.com/bienlanguages'
    }
  ]
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCommonInfo(state, action) {
      state.commonInfo = action.payload
    },
    setContactInfo(state, action) {
      state.contactInfo = action.payload
    },
    setMediaInfo(state, action) {
      state.mediaInfo = action.payload
    }
  }
})

export const { setCommonInfo, setContactInfo, setMediaInfo } = commonSlice.actions
export default commonSlice.reducer
