import React from 'react'
import { SvgIcon } from '@mui/material'

// Google
export function Google(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill="#4285F4"
        d="m23.65619,12.26796c0,-0.78403 -0.06947,-1.52835 -0.18856,-2.25284l-11.21456,0l0,4.4759l6.42108,0c-0.28781,1.46881 -1.13138,2.70936 -2.38185,3.55293l0,2.97732l3.83081,0c2.24291,-2.0742 3.53308,-5.13091 3.53308,-8.75331l0,0z"
      />
      <path
        fill="#34A853"
        d="m12.25307,23.90926c3.2155,0 5.90501,-1.07183 7.87004,-2.888l-3.83081,-2.97732c-1.07183,0.71456 -2.43148,1.15123 -4.03922,1.15123c-3.10633,0 -5.73629,-2.09405 -6.67911,-4.9225l-3.94991,0l0,3.06663c1.9551,3.89036 5.97448,6.56994 10.62902,6.56994z"
      />
      <path
        fill="#FBBC05"
        d="m5.57396,14.27269c-0.24811,-0.71456 -0.37713,-1.47873 -0.37713,-2.27268c0,-0.79395 0.13894,-1.55813 0.37713,-2.27268l0,-3.06663l-3.94991,0c-0.8138,1.60775 -1.28025,3.41399 -1.28025,5.33932c0,1.92533 0.46645,3.73157 1.28025,5.33932l3.94991,-3.06663l0,-0.00001z"
      />
      <path
        fill="#EA4335"
        d="m12.25307,4.80482c1.75662,0 3.32467,0.60539 4.56522,1.78639l3.39414,-3.39414c-2.05435,-1.92533 -4.74386,-3.10633 -7.95936,-3.10633c-4.65454,0 -8.67391,2.67958 -10.62902,6.56994l3.94991,3.06663c0.94282,-2.82845 3.57278,-4.9225 6.67911,-4.9225l0,0z"
      />
    </SvgIcon>
  )
}
