import React from 'react'
import PropTypes from 'prop-types'
import { Backdrop, CircularProgress } from '@mui/material'

Loading.propTypes = {
  isLoading: PropTypes.bool
}

export default function Loading({ isLoading = true, ...other }) {
  return (
    <Backdrop open={isLoading} transitionDuration={500} {...other}>
      <CircularProgress color={'primary'} />
    </Backdrop>
  )
}
