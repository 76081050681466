import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '130px',
    flex: '0 0 130px',
    width: '100%',
    margin: '0 auto',
    zIndex: '20',
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 1px #606060',
    [theme.breakpoints.down('md')]: {
      height: '70px',
      flex: '0 0 70px'
    }
  },
  main: {
    height: '100%',
    maxWidth: '1400px',
    margin: '0 auto',
    fontFamily: "'Noto Sans TC', 'Microsoft JhengHei', sans-serif",
    '& > .leftSide': {
      height: '100%',
      '& > .logoAnchor': {
        height: '100%',
        padding: '0.5em',
        flexDirection: 'column',
        gap: '2px',
        fontWeight: 'bold',
        fontSize: '1.2em',
        '& > img': {
          height: '80px',
          width: 'auto',
          [theme.breakpoints.down('md')]: {
            height: '40px'
          }
        },
        '& > label': {
          letterSpacing: '1px',
          display: 'block',
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        }
      }
    },
    '& > .rightSide': {
      fontSize: '1em',
      '& > .desktopMode': {
        height: '100%',
        padding: '0.5em',
        '& > .navBar': {
          height: '50%',
          '& > .navigation': {
            position: 'relative',
            display: 'inline-block',
            '& > .funcAnchor': {
              height: '100%',
              padding: '0.5em',
              fontWeight: 'bold',
              fontSize: '1em',
              letterSpacing: '1px',
              whiteSpace: 'nowrap'
            },
            '& > .navigationContent': {
              zIndex: '1',
              display: 'none',
              position: 'absolute',
              backgroundColor: '#fff',
              minWidth: '100px',
              boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)',
              letterSpacing: '1px'
            },
            '&:hover .navigationContent': {
              display: 'block'
            }
          }
        },
        '& > .searchInput': {
          height: '50%',
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.primary.main
          },
          '& .MuiButton-contained': {
            minWidth: 'auto',
            padding: '8px 12px',
            borderRadius: '20px'
          },
          '& .MuiOutlinedInput-input': {
            cursor: 'pointer',
            letterSpacing: '1px',
            paddingLeft: '20px'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            right: '14px',
            borderRadius: '20px'
          }
        },
        [theme.breakpoints.down('md')]: {
          display: 'none'
        }
      },
      '& > .mobileMode': {
        [theme.breakpoints.up('md')]: {
          display: 'none'
        }
      }
    }
  },
  drawer: {
    width: '250px',
    maxWidth: '100vw',
    fontFamily: "'Noto Sans TC', 'Microsoft JhengHei', sans-serif",
    '& > .topSide': {
      flexShrink: '0',
      height: '70px',
      width: '100%',
      padding: '0 0.5em',
      boxShadow: '0px 0px 1px #606060',
      '& > a, & > button': {
        '&:hover, &:focus': {
          color: (theme) => theme.palette.primary.main,
          backgroundColor: '#0000000a'
        },
        '& .MuiTouchRipple-child': {
          backgroundColor: (theme) => theme.palette.primary.main
        }
      }
    },
    '& > .bottomSide': {
      '& > .navigation': {
        position: 'relative',
        display: 'inline-block',
        padding: '0.5em',
        width: '100%',
        '& > .funcAnchor': {
          minHeight: '50px',
          width: '100%',
          padding: '0.5em',
          fontWeight: 'bold',
          fontSize: '1.125em',
          letterSpacing: '2px',
          whiteSpace: 'nowrap'
        },
        '& .MuiListItemButton-root': {
          textAlign: 'center !important',
          letterSpacing: '4px',
          whiteSpace: 'nowrap'
        }
      }
    }
  }
}))

export { useStyles }
