import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCommonInfo, setContactInfo, setMediaInfo } from '../../store/slice/commonSlice'
import { setUsername, setUserKind } from '../../store/slice/authSlice'
import { useStyles } from './appMainCSS'
import theme from '../../theme'
import useSWR from 'swr'
import { getBasicData, getApiCommon } from '../../api/CommonService'
import Header from '../../components/Header'
import Contents from '../../components/Contents'
import Footer from '../../components/Footer'

export default function AppMain() {
  const classes = useStyles(theme)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  // fetch
  const { data: basicData } = useSWR([getBasicData, {}])
  const { data: apiCommon } = useSWR([getApiCommon, {}])

  useEffect(() => {
    handleInit()
    handleCheckLogin()
  }, [])

  const handleInit = () => {
    const { result, data } = basicData

    switch (result) {
      case 200: {
        dispatch(setCommonInfo(data.commonInfo))
        dispatch(setContactInfo(data.contactInfo))
        dispatch(setMediaInfo(data.mediaInfo))
      }
    }
  }

  const handleCheckLogin = () => {
    const { result } = apiCommon

    switch (result) {
      case 200: {
        if (localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_AUTH_KEY)) {
          const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_AUTH_KEY))
          dispatch(setUsername(userData.username))
          dispatch(setUserKind(userData.userKind))
        }
        break
      }
      default: {
        localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_AUTH_KEY)
        dispatch(setUsername(''))
        dispatch(setUserKind('public'))
        break
      }
    }

    // replace should visit pathname
    setTimeout(() => navigate(location.pathname + location.search, { replace: true }), 200)
  }

  return (
    <main className={classes.root}>
      <Header />
      <article className={classes.main}>
        <Contents />
        <Footer />
      </article>
    </main>
  )
}
