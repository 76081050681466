import React from 'react'
import { createPack } from '../../components/CreatePack'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { SWRConfig } from 'swr'
import theme from '../../theme'
import store from '../../store'
import CssBaseline from '@mui/material/CssBaseline'
import Pace from '../../components/Pace'
import AutoChangeWebsiteTitle from '../../components/AutoChangeWebsiteTitle'
import ScrollToTop from '../../components/ScrollToTop'
import MediaInfoLink from '../../components/MediaInfoLink'
import AppMain from '../../components/AppMain'

export default function App() {
  const CombineProvider = createPack(
    <Provider store={store} />,
    <SnackbarProvider maxSnack={3} />,
    <BrowserRouter basename={process.env.PUBLIC_URL} />,
    <ThemeProvider theme={theme} />,
    <SWRConfig
      value={{
        suspense: true,
        revalidateOnFocus: false,
        fetcher: (method = () => undefined, param = {}) => method(param)
      }}
    />
  )

  return (
    <CombineProvider>
      <>
        <CssBaseline />
        <Pace color={theme.palette.primary.main} />
        <AutoChangeWebsiteTitle />
        <ScrollToTop />
        <MediaInfoLink />
      </>

      <AppMain />
    </CombineProvider>
  )
}
