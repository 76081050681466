import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, matchPath } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { isEmptyFunc } from '../../utils/LengthUtils'
import routes from '../../routes'

export default function AutoChangeWebsiteTitle() {
  const location = useLocation()
  const { t } = useTranslation()
  const websiteTitle = 'common.website_title'

  const handleFindTitle = () => {
    const pageName = routes.find((item) => matchPath(item.path, location.pathname))?.name

    return isEmptyFunc(pageName) ? t(websiteTitle) : `${t(pageName)} | ${t(websiteTitle)}`
  }

  return (
    <Helmet>
      <title>{handleFindTitle()}</title>
    </Helmet>
  )
}
