import { createTheme, responsiveFontSizes } from '@mui/material/styles'

export default responsiveFontSizes(
  createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#7f0019',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#ff3661',
        contrastText: '#ffffff'
      },
      info: {
        main: '#4769b0',
        contrastText: '#ffffff'
      },
      success: {
        main: '#06c755',
        contrastText: '#ffffff'
      }
    },
    typography: {
      fontFamily:
        '"Noto Sans TC","Microsoft JhengHei","Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
    }
  })
)
