import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
  const location = useLocation()
  // in this project, which is main container
  const targetEl = window || document.getElementsByTagName('main')?.[0]

  useEffect(() => {
    targetEl?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location.pathname])

  return null
}
