import React from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery, SpeedDial, SpeedDialAction } from '@mui/material'
import { Share } from '@mui/icons-material'

export default function MediaInfoLink() {
  const { mediaInfo } = useSelector((state) => state.common)
  // check both sides have space
  const isShow = useMediaQuery('(min-width: 900px)')
  const isTranslucent = useMediaQuery(`(min-width: ${1400 + 90 * 2}px)`)

  return (
    <SpeedDial
      ariaLabel={'Share This Page'}
      icon={<Share />}
      transitionDuration={{ appear: 0, enter: 200, exit: 400 }}
      direction={'down'}
      open={isShow}
      hidden={!isShow}
      FabProps={{ sx: { display: 'none' } }}
      sx={{
        position: 'fixed',
        top: 'calc(50% - 225px)',
        right: !isTranslucent ? '0px' : '0.5em',
        opacity: !isTranslucent ? '0.6' : '1',
        transition: 'all 0.2s ease-in',
        '&:hover': { opacity: '1' }
      }}
    >
      {mediaInfo.map((item, index) => (
        <SpeedDialAction
          key={index}
          arrow={true}
          icon={<img src={item.imageUrl} style={{ width: '100%' }} />}
          tooltipTitle={`前往 ${item.name}`}
          onClick={() => window.open(item.imageLink, '_blank')}
          sx={{
            width: !isTranslucent ? '40px' : '60px',
            height: !isTranslucent ? '40px' : '60px',
            overflow: 'hidden',
            backgroundColor: 'transparent !important',
            transition: 'all 0.2s ease-in'
          }}
        />
      ))}
    </SpeedDial>
  )
}
